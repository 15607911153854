<template>
  <div>
    <page-title heading="Dirmeier Schanktechnik" subheading="Dirmeier Schanktechnik" :icon=icon></page-title>
    <div class="app-main__inner">
      <DirmeierComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import DirmeierComponent from "@/components/settings/moduleSettings/DirmeierComponent";
export default {
  name: "Dirmeier",
  components: {DirmeierComponent, PageTitle},
  data(){
    return{
      icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
